import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import VideoPage from './components/VideoPage';
function App() {
  return (
    <div className="App">
     <Routes> 
        <Route path="/" element={<Login/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/videopage" element={<VideoPage/>}/>
      </Routes>
    </div>
  );
}

export default App;

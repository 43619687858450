// import { createStore, combineReducers } from 'redux'
import { getFleetListAndRobotStatusByUserEmailReducer } from './reducers/fleet';
import { userLoginReducer } from "./reducers/user"
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const initialState = {}

const reducers = combineReducers({
    login: userLoginReducer,
    fleetList: getFleetListAndRobotStatusByUserEmailReducer,
})

const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

export default store